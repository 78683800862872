@font-face {
    font-family: "Rampart";
    src: local("Rampart"),
     url("./RampartOne-Regular.ttf") format("truetype");
    font-weight: bold;
   }
   

@font-face {
    font-family: "BungeeInline";
    src: local("BungeeInline"),
     url("./BungeeInline-Regular.ttf") format("truetype");
    font-weight: bold;
   }

@font-face {
    font-family: "Syncopate";
    src: local("Syncopate"),
     url("./Syncopate-Regular.ttf") format("truetype");
    font-weight: regular;
   }

@font-face {
    font-family: "Roboto";
    src: local("Roboto"),
     url("./Syncopate-Regular.ttf") format("truetype");
    font-weight: regular;
   }

@font-face {
    font-family: "Playfair";
    src: local("Playfair"),
     url("./PlayfairDisplay-VariableFont_wght.ttf") format("truetype");
    font-weight: regular;
   }

@font-face {
    font-family: "Rajdhani";
    src: local("Rajdhani"),
     url("./Rajdhani-Light.ttf") format("truetype");
    font-weight: regular;
   }

@font-face {
    font-family: "Merriweather";
    src: local("Merriweather"),
     url("./Merriweather-Light.ttf") format("truetype");
    font-weight: regular;
   }
   
