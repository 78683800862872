.App {
  font-family: sans-serif;
  text-align: center;
}

.slide {
  position: relative;
  left: 0%;
  width: 90%;
  height: 10vh;
  margin-left: 10px;
  margin-right: 10px;
}

.show {
  position: absolute;
  width: 90%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: fade 1s ease-in-out;
}

.show > div {
  width: 90%;
  height: 250px;
}

/*img {
  width: 100%;
  height: 100%;
}*/

/* @-webkit-keyframes trans {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
}

@keyframes trans {
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
} */

@keyframes fade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.not-show {
  display: none;
}

.prev {
  position: absolute;
  top: 50%;
  left: 1px;
  transform: translateY(-50%);
}

.next {
  position: absolute;
  top: 50%;
  right: 1px;
  transform: translateY(-50%);
}

button {
  border: 0;
  background-color: Transparent;
  font-size: 20px;
}

.page {
  text-align: center;
}
.dot {
  cursor: pointer;
  height: 5px;
  width: 20px;
  margin: 0 2px;
  background-color: #ddd;
  display: inline-flex;
  transition: background-color 8.0s ease;
}

button:hover,
.dot:hover,
.active {
  background-color: #717171;
  opacity: 1;
}
